import { IosShare } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import React, { useState } from 'react';
import { useAlertContext } from '../../../context/useAlertContext';
import { useCheckContext } from '../hooks/useCheckContext';

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://www.splitit.dev' : 'http://localhost:3000';

export const ShareCheckButton: React.FC = () => {
  const { check } = useCheckContext();
  const { setSuccess, setError } = useAlertContext();
  const [open, setOpen] = useState(false);

  if (!check) return null;

  const shareUrl = `${BASE_URL}/checks/${check.id}?share_code=${check.shareCode}`;

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setSuccess('Link copied 🎉 Share with friends!');
    } catch (err) {}
  };

  const handleShare = async () => {
    if (!navigator.share) {
      await handleCopyLink();
      return;
    }

    try {
      await navigator.share({
        title: `Let's split the bill for ${check.restaurant.name} 🎉`,
        url: shareUrl,
      });
    } catch (error) {}
  };

  const handleClick = () => {
    handleCopyLink();
    setOpen(true);
  };

  return (
    <>
      <Button onClick={handleClick} startIcon={<IosShare />} color="secondary" variant="contained">
        Share Split
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xs">
        <DialogContent>
          <Stack spacing={2}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h5" gutterBottom>
                Split with friends! 🎉
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Pick your favorite way to share:
              </Typography>
            </Box>

            <Stack spacing={1}>
              <Button variant="contained" onClick={handleShare} startIcon={<IosShare />} fullWidth size="large">
                Share with contacts
              </Button>

              <Box sx={{ textAlign: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Box sx={{ flex: 1, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} />
                  <Box sx={{ px: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      or
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} />
                </Box>
                <Typography variant="body1" gutterBottom>
                  Scan together at the table
                </Typography>

                <QRCodeSVG value={shareUrl} size={150} />
              </Box>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
