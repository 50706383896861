import { useAuth } from '@clerk/clerk-react';
import { ApiRequest } from '../common/api-request.type';
import { apiError } from '../common/errors';
import { useAlertContext } from '../context/useAlertContext';
import { useLoadingContext } from '../context/useLoadingContext';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://split-it-api.fly.dev' : 'http://localhost:3001';
export const API_BASE_URL = `${API_URL}/api/v1`;

type ApiCall = {
  request: ApiRequest;
  endpoint: string;
  method: RequestInit['method'];
  body?: RequestInit['body'];
  isFormData?: boolean;
  allowError?: boolean;
};

export const useApiCall = () => {
  const { setIsLoading } = useLoadingContext();
  const { setError } = useAlertContext();
  const { getToken } = useAuth();

  const callApi = async (apiCall: ApiCall): Promise<any> => {
    setIsLoading(true);

    try {
      const headers: HeadersInit = {};

      const token = await getToken();
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      if (!apiCall.isFormData) {
        headers['Content-Type'] = 'application/json';
      }

      const response = await fetch(`${API_BASE_URL}${apiCall.endpoint}`, {
        method: apiCall.method,
        body: apiCall.body,
        headers,
      });

      const body = await response.json();
      if (!response.ok) {
        if (!apiCall.allowError) {
        setError(apiError(apiCall.request, body.code));
        }
        return null;
      }

      return body;
    } catch (error) {
      if (!apiCall.allowError) {
      setError(apiError(apiCall.request, 'error'));
      }
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return callApi;
};
