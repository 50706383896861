import { Avatar, Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { SplitCoins } from '../../common/SplitCoins';

export const HeroSection: React.FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
      <Avatar sx={{ width: 100, height: 100, mt: 2, mb: 1 }} alt="Split It Logo" src="/splitit-logo.png" />
      <Typography variant="h1" color="primary" sx={{ mb: 2 }}>
        Split It
      </Typography>
      <Typography variant="h2" color="textSecondary" gutterBottom>
        The Fastest Way to Split Your Restaurant Bill
      </Typography>
      <Typography variant="h6" color="primary" paragraph>
        Effortless bill splitting for any group size. Scan, split, and get paid back instantly.
      </Typography>
      <Button
        variant="contained"
        title="Start Splitting for Free"
        color="primary"
        size="large"
        sx={{ mt: 2 }}
        component={Link}
        to="/dashboard"
      >
        Start Splitting for Free
      </Button>
      <Typography variant="body2" sx={{ mt: 2 }}>
        No credit card required. Get one free <SplitCoins singular /> when you sign up!
      </Typography>
    </Box>
  );
};
