import { SignedOut, SignUpButton } from '@clerk/clerk-react';
import { Person } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';

export const SignUpToSaveSplit: React.FC = () => {
  return (
    <SignedOut>
      <SignUpButton forceRedirectUrl={window.location.href}>
        <Button color="primary" variant="contained" startIcon={<Person />}>
          Sign up to save this Split
        </Button>
      </SignUpButton>
    </SignedOut>
  );
};
