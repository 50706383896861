import { useSearchParams } from 'react-router-dom';

interface AuthCodesState {
  [checkId: string]: string;
}

interface UseAuthCodesReturn {
  setAuthCode: (checkId: string, code: string) => void;
  getAuthCode: (checkId: string) => string | null;
}

const STORAGE_KEY = 'split_it_check_auth_codes';

const getStoredAuthCodes = (): AuthCodesState => {
  try {
    const stored = localStorage.getItem(STORAGE_KEY);
    return stored ? JSON.parse(stored) : {};
  } catch (e) {
    return {};
  }
};

const setStoredAuthCodes = (codes: AuthCodesState): void => {
  try {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(codes));
  } catch (e) {
    console.error('Failed to save auth codes to localStorage:', e);
  }
};

export function useAuthCodes(): UseAuthCodesReturn {
  const [searchParams] = useSearchParams();

  const setAuthCode = (checkId: string, code: string): void => {
    const codes = {
      ...getStoredAuthCodes(),
      [checkId]: code,
    };
    setStoredAuthCodes(codes);
  };

  const getAuthCode = (checkId: string): string | null => {
    const storedCodes = getStoredAuthCodes();
    return storedCodes[checkId] || searchParams.get('auth_code') || null;
  };

  return {
    setAuthCode,
    getAuthCode,
  };
}
