import { Add, CalendarMonth, Dashboard, Person } from '@mui/icons-material';
import { Avatar, Box, Button, Card, CardContent, Grid2 as Grid, Typography } from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMySplitTokensContext } from '../../../hooks/useMySplitTokensContext';
import { useUserContext } from '../../../hooks/useUserContext';
import { SplitCoinImage } from '../../common/SplitCoins';

interface UserHeaderProps {
  showButtons?: boolean;
}

export const UserHeader: React.FC<UserHeaderProps> = ({ showButtons = true }) => {
  const { user } = useUserContext();
  const { mySplitTokens } = useMySplitTokensContext();

  const { pathname } = useLocation();
  const isDashboard = pathname === '/dashboard';

  const balance = mySplitTokens.availableTokens;

  if (!user) return null;

  return (
    <Card sx={{ mb: 1 }}>
      <CardContent>
        <Grid container alignItems="center" spacing={2}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {user.profileImageUrl ? (
                <Avatar sx={{ width: 56, height: 56, mr: 2 }} src={user.profileImageUrl} alt={user.name} />
              ) : (
                <Avatar sx={{ width: 56, height: 56, bgcolor: 'primary.main', mr: 2 }}>
                  <Person />
                </Avatar>
              )}
              <Box>
                <Typography variant="h5">{user.name}</Typography>
                <Typography variant="body2" color="text.secondary">
                  <CalendarMonth sx={{ fontSize: 'small', mr: 1, verticalAlign: 'middle' }} />
                  Member since {user.memberSince}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {showButtons && (
            <Grid
              size={{ xs: 12, sm: 6 }}
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', sm: 'flex-end' },
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                sx={{ mb: { xs: 1, sm: 0 }, mr: { sm: 1 }, fontWeight: 'bold', width: { xs: '100%', sm: 'auto' } }}
                component={Link}
                to="/new-check"
              >
                New Split
              </Button>
              <Button
                startIcon={<SplitCoinImage large={false} />}
                variant="outlined"
                component={Link}
                to="/tokens"
                sx={{ mb: { xs: 1, sm: 0 }, mr: { sm: 1 }, width: { xs: '100%', sm: 'auto' } }}
              >
                {balance} SplitCoin{balance === 1 ? '' : 's'} • View
              </Button>
              <Button
                startIcon={isDashboard ? <Person /> : <Dashboard />}
                variant="outlined"
                component={Link}
                to={isDashboard ? '/profile' : '/dashboard'}
              >
                {isDashboard ? 'Edit Profile' : 'View Dashboard'}
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
