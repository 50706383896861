import { Box, TextField, Typography } from '@mui/material';
import React from 'react';
import { $ } from '../../../util/formatCurrency.util';

interface ReceiptRowProps {
  title: string;
  amount: number | string;
  isBold?: boolean;
  isMoney?: boolean;
}

interface InputReceiptRowProps extends ReceiptRowProps {
  setter: React.Dispatch<React.SetStateAction<number>>;
}

export const ReceiptRow: React.FC<ReceiptRowProps> = ({ title, amount, isBold = false, isMoney = true }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
    <Typography variant="body1" fontWeight={isBold ? 'bold' : 'normal'}>
      {title}
    </Typography>
    <Typography variant="body1" fontWeight={isBold ? 'bold' : 'normal'}>
      {isMoney ? $(amount as number) : amount}
    </Typography>
  </Box>
);
export const InputReceiptRow: React.FC<InputReceiptRowProps> = ({ title, amount, isBold = false, setter }) => {
  const handleUpdateNumericData = (value: string) => {
    if (value === '') {
      setter(0);
      return;
    }
    setter(+value);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 1 }}>
      <Typography variant="body1" fontWeight={isBold ? 'bold' : 'normal'}>
        {title}
      </Typography>
      <TextField
        type="number"
        value={amount || ''}
        onChange={(e) => handleUpdateNumericData(e.target.value)}
        variant="filled"
        InputProps={{
          startAdornment: '$',
          inputProps: {
            inputMode: 'decimal',
            pattern: '[0-9]*[.,]?[0-9]{0,2}',
            style: {
              textAlign: 'right',
              padding: '4px 10px 4px 0',
            },
            sx: {
              width: { xs: '60px', md: '80px' },
            },
          },
          disableUnderline: true,
        }}
      />
    </Box>
  );
};
