import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { ApiRequest } from '../common/api-request.type';
import { useAlertContext } from '../context/useAlertContext';
import { User } from '../models/User.model';
import { UserStats } from '../models/UserStats.model';
import { useApiCall } from './useApiCall';
import { useAuthContext } from './useAuthContext';

type UpdateUserParams = {
  firstName?: string;
  lastName?: string;
  venmoUsername?: string;
};

interface UserContextType {
  user: User | null;
  userStats: UserStats | null;
  updateUser: ({ venmoUsername, firstName, lastName }: UpdateUserParams) => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const callApi = useApiCall();
  const { isSignedIn } = useAuthContext();
  const { setSuccess } = useAlertContext();

  const [user, setUser] = useState<User | null>(null);
  const [userStats, setUserStats] = useState<UserStats | null>(null);

  const updateUser = async ({ venmoUsername, firstName, lastName }: UpdateUserParams) => {
    const user = await callApi({
      request: ApiRequest.UpdateUser,
      endpoint: '/users/me',
      method: 'PUT',
      body: JSON.stringify({ first_name: firstName, last_name: lastName, venmo_username: venmoUsername }),
    });

    if (!user) {
      return;
    }

    setSuccess('Profile updated successfully!');
    setUser(new User(user));
  };

  const getUser = async () => {
    if (!isSignedIn) return;

    const data = await callApi({
      request: ApiRequest.GetMyUserInfo,
      endpoint: '/users/me',
      method: 'GET',
      body: null,
    });

    if (!data) {
      return;
    }

    const user = new User(data);
    setUser(user);
    return user;
  };

  const getUserStats = async () => {
    if (!user) return;

    const statsData = await callApi({
      request: ApiRequest.GetMyUserStats,
      endpoint: '/users/me/stats',
      method: 'GET',
      body: null,
    });

    setUserStats(new UserStats(statsData));
  };

  useEffect(() => {
    const retryGetUser = async (retries = 10, delay = 1000) => {
      for (let i = 0; i < retries; i++) {
        const result = await getUser();
        if (result) return;

        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    };

    if (isSignedIn) {
      retryGetUser();
    }
  }, [isSignedIn]);

  useEffect(() => {
    getUserStats();
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        userStats,
        updateUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};
