import { ApiRequest } from './api-request.type';

const ERROR_MESSAGES: Partial<Record<ApiRequest, Record<string, string>>> = {
  [ApiRequest.CreateUser]: {
    missing_new_user_fields: 'You did not enter all of the required fields.',
    passwords_do_not_match: 'Your passwords did not match.',
    email_already_taken: 'There is already an account with this email address.',
    username_already_taken: 'This username is already associated with an account.',
    default: 'There was an error creating your user.',
  },
  [ApiRequest.UpdateUser]: {
    email_already_taken: 'There is already an account with this email address.',
    username_already_taken: 'This username is already associated with an account.',
    default: 'There was an error updating your user information.',
  },
  [ApiRequest.GetMyUserInfo]: {
    default: 'There was an error loading your Split It user information. Please try again.',
  },
  [ApiRequest.GetMyUserStats]: {
    default: 'There was an error loading your Split It stats. Please try again.',
  },
  [ApiRequest.GetMySplitTokens]: {
    default: 'There was an error loading your Split It user information. Please try again.',
  },
  [ApiRequest.GetMySplitTokensOrders]: {
    default: 'There was an error loading your SplitCoin balance. Please try again.',
  },
  [ApiRequest.ClaimItem]: {
    invalid_check_item_quantity: 'Whoops! 🍽️ Looks like someone has already claimed this!',
    default: 'There was an error claiming this item',
  },
  [ApiRequest.GetMyChecks]: {
    default: 'There was an error loading your splits. Please try again.',
  },
  [ApiRequest.GetCheckByShareCode]: {
    default: 'Sorry, we could not find this bill. Please ask the owner to share the link again.',
  },
  [ApiRequest.JoinCheck]: {
    join_check_nickname_taken: 'There was an error joining this bill. Try a different nickname.',
    join_check_request_invalid: 'There was an error joining this bill. Be sure to add a nickname.',
    default: 'There was an error joining this bill. Make sure you have the right code.',
  },
  [ApiRequest.CreateCheck]: {
    default: 'Error creating bill. Make sure you added a restaurant name, tax, and tip.',
  },
  [ApiRequest.ParseReceipt]: {
    default: 'Error parsing receipt. Make sure it is a legible photo.',
  },
  [ApiRequest.PurchaseSplitTokens]: {
    default: 'There was an error purchasing SplitCoins. Please try again.',
  },
};

export const apiError = (request: ApiRequest, code: string) => {
  const errors = ERROR_MESSAGES[request];
  if (!errors) {
    return 'An unexpected error occurred. Please try again later.';
  }

  return errors[code] ?? errors.default;
};
